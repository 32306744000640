import HeadTitle from "@/components/layouts/HeadTitle";
import { LoginLayout } from "@fyvedev/fe-storybook";
import { LoginIllustration, SignInForm } from "components/templates/login";
import { HeaderNav } from "lib/const/common";
import { getRedirect } from "lib/hooks/getRedirect";
import { GetServerSideProps } from "next";
import { NextPageWithLayout } from "types/next-page-with-layout";
import React from "react";

export const getServerSideProps: GetServerSideProps = async ({
  req,
  query,
}) => {
  return getRedirect(req, query);
};

const LoginPage: NextPageWithLayout = () => (
  <>
    <HeadTitle page={HeaderNav.FYVE} />
    <LoginIllustration />
    <SignInForm />
  </>
);

LoginPage.getLayout = (page) => <LoginLayout>{page}</LoginLayout>;

export default LoginPage;
